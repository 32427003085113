<template>
  <component v-if="userType" v-bind:is="activeDashboardComponent"></component>
</template>

<script>
import { userTypeIdToComponent } from '@/core/helpers';
import DashboardAdmin from './Admin/DashboardAdmin.vue';
import DashboardLabtechnician from './Labtechnician/DashboardLabtechnician.vue';
import DashboardClinician from './Clinician/DashboardClinician.vue';
import DashboardPathologist from './Pathologist/DashboardPathologist.vue';
// administrator: Administrator,
// clinicAssociate: ClinicAssociate,
// clinician: Clinician,
// clinicianAssociate: ClinicianAssociate,
// labTechnician: LabTechnician,
// pathologist: Pathologist,
const activeDashboards = {
  administrator: DashboardAdmin,
  labTechnician: DashboardLabtechnician,
  pathologist: DashboardPathologist,
};
export default {
  components: { DashboardAdmin },
  computed: {
    activeDashboardComponent: function () {
      if (this.userType === 'administrator' || this.userType === 'labTechnician' || this.userType === 'pathologist') {
        return activeDashboards[this.userType];
      }
      return DashboardClinician;
    },
  },
  data: function () {
    return {
      userType: false,
    };
  },
  created() {
    this.userType = userTypeIdToComponent(this.USER_TYPES(), this.$store.getters['auth/userType']);
  },
};
</script>

<style></style>
