var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReady)?_c('div',[(_vm.statsData)?_c('div',{staticClass:"md-layout"},[(_vm.isAllow(_vm.statsData.notifications))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('NotificationStats',{attrs:{"data":{ value: _vm.statsData.notifications, time: _vm.lastHours(24) }}})],1):_vm._e(),(_vm.isAllow(_vm.caseListOverViewStats))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('CaseListOverViewStats',{attrs:{"data":{
          value: _vm.caseListOverViewStats,
          time: _vm.currentTime,
        }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.labCases))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('LabCasesStats',{attrs:{"data":{
          last24hours: _vm.statsData.labCases.LabCaseInLast24hour,
          totalCases: _vm.statsData.labCases.LabTotalCase,
          time: _vm.currentTime,
        }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.unreportedCases))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('UnreportedCasesStats',{attrs:{"data":{
          provisionallyReported: _vm.statsData.unreportedCases.ProvisionalReported,
          path: _vm.statsData.unreportedCases.Path,
          time: _vm.currentTime,
        }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.repotedCases))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('ReportedCasesStats',{attrs:{"data":{ value: _vm.statsData.repotedCases, time: _vm.lastHours(48) }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.closedIssues))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('ClosedIssuesStats',{attrs:{"data":{ value: _vm.statsData.closedIssues, time: _vm.lastHours(24) }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.openIssues))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('OpenIssuesStats',{attrs:{"data":{ value: _vm.statsData.openIssues, time: _vm.currentTime }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.outStandingAlwRequest))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('OutstandingALWRequestsStats',{attrs:{"data":{
          new: _vm.statsData.outStandingAlwRequest.LabOutStandingAlwNew,
          rescan: _vm.statsData.outStandingAlwRequest.LabOutStandingAlwRescan,
          time: _vm.currentTime,
        }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.respondedAlws))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('RespondedALWStats',{attrs:{"data":{
          cancelled: _vm.statsData.respondedAlws.LabRespondedAlwCancel,
          completed: _vm.statsData.respondedAlws.LabRespondedAlwComplete,
          time: _vm.lastHours(24),
        }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.slideSentToPath))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('SlidesSentToPathStats',{attrs:{"data":{ value: _vm.statsData.slideSentToPath, time: _vm.currentTime }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.userForValidation))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('UserForValidationStats',{attrs:{"data":{ value: _vm.statsData.userForValidation, time: _vm.currentTime }}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-50"},[(_vm.isReady && _vm.newUsersPerMonth)?_c('NewUsersPerMonthChart',{attrs:{"data":_vm.newUsersPerMonth}}):_vm._e()],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-50"},[(_vm.isReady && _vm.averageTurnAroundTimePerDay)?_c('AverageTurnAroundTimePerDayChart',{attrs:{"data":_vm.averageTurnAroundTimePerDay}}):_vm._e()],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-50"},[(_vm.isReady)?_c('NewEntitiesPerMonthChart',{attrs:{"data":_vm.newEntitiesPerMonth}}):_vm._e()],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-50"},[(_vm.isReady)?_c('DeactivateEntitiesPerMonthChart',{attrs:{"data":_vm.deactivateEntitiesPerMonth}}):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }