var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReady)?_c('div',[_c('div',{staticClass:"md-layout"},[(_vm.isAllow(_vm.statsData.notifications))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('NotificationStats',{attrs:{"data":{ value: _vm.statsData.notifications, time: _vm.lastHours(24) }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.readyForReportCases))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('ReadyForReportCasesStats',{attrs:{"data":{ value: _vm.statsData.readyForReportCases, time: _vm.currentTime }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.pendingFurtherWorkCases))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('PendingFurtherWorkCasesStats',{attrs:{"data":{
          pendingALWSO: _vm.statsData.pendingFurtherWorkCases.PendingAlwSoCase,
          openIssues: _vm.statsData.pendingFurtherWorkCases.PendingIssueCase,
          time: _vm.currentTime,
        }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.reportedCases))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('ReportedCasesStats',{attrs:{"data":{ value: _vm.statsData.reportedCases, time: _vm.lastHours(48) }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.soReceived))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('SoReceivedStats',{attrs:{"data":{ value: _vm.statsData.soReceived, time: _vm.currentTime }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.doubleReportingPartnerRequests))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('DoubleReportingPartnerRequestStats',{attrs:{"data":{ value: _vm.statsData.doubleReportingPartnerRequests, time: _vm.currentTime }}})],1):_vm._e(),(_vm.isAllow(_vm.statsData.doubleReportingRequests))?_c('div',{staticClass:"md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"},[_c('DoubleReportingRequestStats',{attrs:{"data":{
          waitingForResponse: _vm.statsData.doubleReportingRequests.CaseDoubleReportWaitForResponse,
          toBeResponded: _vm.statsData.doubleReportingRequests.CaseDoubleReportToBeResponded,
          time: _vm.currentTime,
        }}})],1):_vm._e()]),_c('div',{staticClass:"md-layout"},[(_vm.isReady && _vm.chartData.casesReportedPerYear)?_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-50"},[_c('CasesReportedPerYearChart',{attrs:{"data":_vm.chartData.casesReportedPerYear}})],1):_vm._e(),(_vm.isReady && _vm.chartData.casesReportedPerDay)?_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-50"},[_c('CasesReportedPerDayChart',{attrs:{"data":_vm.chartData.casesReportedPerDay}})],1):_vm._e(),(_vm.isReady && _vm.chartData.averageTurnAroundTimePerDay)?_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-50"},[_c('AverageTurnAroundTimePerDayChart',{attrs:{"data":_vm.chartData.averageTurnAroundTimePerDay}})],1):_vm._e(),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-50"},[_c('CalendarListPathologist')],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }