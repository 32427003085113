<template>
  <div v-if="isReady">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33">
        <NotificationStats :data="{ value: statsData.notifications, time: lastHours(24) }"></NotificationStats>
      </div>
      <div class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33">
        <PendingCasesStats :data="{ value: statsData.pendingCase, time: currentTime }"></PendingCasesStats>
      </div>
      <div class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33">
        <UnreportedCasesStats
          :data="{
            provisionallyReported: statsData.unreportedCase.ProvisionalReported,
            path: statsData.unreportedCase.Path,
            time: currentTime,
          }"
        ></UnreportedCasesStats>
      </div>
      <div class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33">
        <ReportedCasesStats :data="{ value: statsData.reportedCase, time: lastWeek(2) }"></ReportedCasesStats>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NotificationStats,
  PendingCasesStats,
  UnreportedCasesStats,
  ReportedCasesStats,
} from '@/pages/Dashboard/Components/index';
import dashboardMixins from '@/pages/Dashboard//dashboard.mixins';
import dashboardService from '@/services/dashboard.service';

export default {
  mixins: [dashboardMixins],
  components: {
    NotificationStats,
    PendingCasesStats,
    UnreportedCasesStats,
    ReportedCasesStats,
  },
  computed: {},
  created() {},

  async mounted() {
    if (!this.isReady) {
      this.statsData = await this.fetchStatsData();
      this.isReady = true;
    }
  },
  data: function () {
    return {
      statsData: null,
      isReady: false,
    };
  },
  methods: {
    async fetchStatsData() {
      const { data, error } = await dashboardService.getDashboardAssociatedStats();
      if (error) {
        this.$alertError(error);
        return null;
      }
      return this.transformStatsData(data);
    },
  },
};
</script>

<style></style>
