<template>
  <div v-if="isReady">
    <div class="md-layout">
      <div
        v-if="isAllow(statsData.notifications)"
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"
      >
        <NotificationStats :data="{ value: statsData.notifications, time: lastHours(24) }"></NotificationStats>
      </div>
      <div v-if="isAllow(statsData.labCases)" class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33">
        <LabCasesStats
          :data="{
            last24hours: statsData.labCases.LabCaseInLast24hour,
            totalCases: statsData.labCases.LabTotalCase,
            time: currentTime,
          }"
        ></LabCasesStats>
      </div>
      <div
        v-if="isAllow(statsData.releaseCases)"
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"
      >
        <ReleasedCasesStats :data="{ value: statsData.releaseCases, time: lastHours(24) }"></ReleasedCasesStats>
      </div>
      <div
        v-if="isAllow(statsData.slideSentToPath)"
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"
      >
        <SlidesSentToPathStats :data="{ value: statsData.slideSentToPath, time: currentTime }"></SlidesSentToPathStats>
      </div>
      <div
        v-if="isAllow(statsData.outStandingAlwRequest)"
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"
      >
        <OutstandingALWRequestsStats
          :data="{
            new: statsData.outStandingAlwRequest.LabOutStandingAlwNew,
            rescan: statsData.outStandingAlwRequest.LabOutStandingAlwRescan,
            time: currentTime,
          }"
        ></OutstandingALWRequestsStats>
      </div>
      <div
        v-if="isAllow(statsData.respondedAlws)"
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"
      >
        <RespondedALWStats
          :data="{
            cancelled: statsData.respondedAlws.LabRespondedAlwCancel,
            completed: statsData.respondedAlws.LabRespondedAlwComplete,
            time: lastHours(24),
          }"
        ></RespondedALWStats>
      </div>

      <div
        v-if="isAllow(statsData.closedIssues)"
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"
      >
        <ClosedIssuesStats :data="{ value: statsData.closedIssues, time: lastHours(24) }"></ClosedIssuesStats>
      </div>
      <div v-if="isAllow(statsData.openIssues)" class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33">
        <OpenIssuesStats :data="{ value: statsData.openIssues, time: currentTime }"></OpenIssuesStats>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NotificationStats,
  LabCasesStats,
  ReleasedCasesStats,
  ClosedIssuesStats,
  OpenIssuesStats,
  OutstandingALWRequestsStats,
  RespondedALWStats,
  SlidesSentToPathStats,
} from '@/pages/Dashboard/Components/index';

import dashboardMixins from '@/pages/Dashboard//dashboard.mixins';
import dashboardService from '@/services/dashboard.service';

export default {
  mixins: [dashboardMixins],
  components: {
    NotificationStats,
    LabCasesStats,
    ReleasedCasesStats,
    ClosedIssuesStats,
    OpenIssuesStats,
    OutstandingALWRequestsStats,
    RespondedALWStats,
    SlidesSentToPathStats,
  },
  computed: {},
  created() {},

  async mounted() {
    if (!this.isReady) {
      this.statsData = await this.fetchStatsData();
      this.isReady = true;
    }
  },
  data: function () {
    return {
      statsData: null,
      isReady: false,
    };
  },
  methods: {
    async fetchStatsData() {
      const { data, error } = await dashboardService.getDashboardLabtechnicianStats();
      if (error) {
        this.$alertError(error);
        return null;
      }
      return this.transformStatsData(data);
    },
  },
};
</script>

<style></style>
