<template>
  <div v-if="isReady">
    <div class="md-layout">
      <div
        v-if="isAllow(statsData.notifications)"
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"
      >
        <NotificationStats :data="{ value: statsData.notifications, time: lastHours(24) }"></NotificationStats>
      </div>
      <div
        v-if="isAllow(statsData.readyForReportCases)"
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"
      >
        <ReadyForReportCasesStats
          :data="{ value: statsData.readyForReportCases, time: currentTime }"
        ></ReadyForReportCasesStats>
      </div>
      <div
        v-if="isAllow(statsData.pendingFurtherWorkCases)"
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"
      >
        <PendingFurtherWorkCasesStats
          :data="{
            pendingALWSO: statsData.pendingFurtherWorkCases.PendingAlwSoCase,
            openIssues: statsData.pendingFurtherWorkCases.PendingIssueCase,
            time: currentTime,
          }"
        ></PendingFurtherWorkCasesStats>
      </div>
      <div
        v-if="isAllow(statsData.reportedCases)"
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"
      >
        <ReportedCasesStats :data="{ value: statsData.reportedCases, time: lastHours(48) }"></ReportedCasesStats>
      </div>
      <div v-if="isAllow(statsData.soReceived)" class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33">
        <SoReceivedStats :data="{ value: statsData.soReceived, time: currentTime }"></SoReceivedStats>
      </div>
      <div
        v-if="isAllow(statsData.doubleReportingPartnerRequests)"
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"
      >
        <DoubleReportingPartnerRequestStats
          :data="{ value: statsData.doubleReportingPartnerRequests, time: currentTime }"
        ></DoubleReportingPartnerRequestStats>
      </div>
      <div
        v-if="isAllow(statsData.doubleReportingRequests)"
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33"
      >
        <DoubleReportingRequestStats
          :data="{
            waitingForResponse: statsData.doubleReportingRequests.CaseDoubleReportWaitForResponse,
            toBeResponded: statsData.doubleReportingRequests.CaseDoubleReportToBeResponded,
            time: currentTime,
          }"
        ></DoubleReportingRequestStats>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-small-size-50" v-if="isReady && chartData.casesReportedPerYear">
        <CasesReportedPerYearChart :data="chartData.casesReportedPerYear"></CasesReportedPerYearChart>
      </div>
      <div class="md-layout-item md-size-50 md-small-size-50" v-if="isReady && chartData.casesReportedPerDay">
        <CasesReportedPerDayChart :data="chartData.casesReportedPerDay"></CasesReportedPerDayChart>
      </div>
      <div class="md-layout-item md-size-50 md-small-size-50" v-if="isReady && chartData.averageTurnAroundTimePerDay">
        <AverageTurnAroundTimePerDayChart
          :data="chartData.averageTurnAroundTimePerDay"
        ></AverageTurnAroundTimePerDayChart>
      </div>
      <div class="md-layout-item md-size-50 md-small-size-50">
        <CalendarListPathologist></CalendarListPathologist>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NotificationStats,
  DoubleReportingPartnerRequestStats,
  DoubleReportingRequestStats,
  PendingFurtherWorkCasesStats,
  ReadyForReportCasesStats,
  SoReceivedStats,
  ReportedCasesStats,
  CasesReportedPerYearChart,
  CasesReportedPerDayChart,
  AverageTurnAroundTimePerDayChart,
  CalendarListPathologist,
} from '@/pages/Dashboard/Components/index';

import perfectScrollBarMixins from '@/pages/Dashboard/perfectScrollBar.mixins';
import dashboardMixins from '@/pages/Dashboard//dashboard.mixins';
import dashboardService from '@/services/dashboard.service';

export default {
  mixins: [perfectScrollBarMixins, dashboardMixins],
  components: {
    NotificationStats,
    DoubleReportingPartnerRequestStats,
    DoubleReportingRequestStats,
    PendingFurtherWorkCasesStats,
    ReadyForReportCasesStats,
    SoReceivedStats,
    ReportedCasesStats,
    CasesReportedPerYearChart,
    CasesReportedPerDayChart,
    AverageTurnAroundTimePerDayChart,
    CalendarListPathologist,
  },
  computed: {},
  created() {},

  async mounted() {
    if (!this.isReady) {
      this.chartData = await this.fetchData();
      this.statsData = await this.fetchStatsData();
      this.isReady = true;
      this.$nextTick(() => {
        this.initPerfectScrollBar();
      });
    }
  },
  data: function () {
    return {
      isReady: false,
      chartData: null,
      statsData: null,
    };
  },
  methods: {
    async fetchData() {
      const casesReportedPerYear = await this.fetchCasesReportedPerYearData();
      const casesReportedPerDay = await this.fetchCasesReportedPerYearDay();
      const averageTurnAroundTimePerDay = await this.fetchAverageTurnAroundData();
      return {
        casesReportedPerYear,
        averageTurnAroundTimePerDay,
        casesReportedPerDay,
      };
    },
    async fetchStatsData() {
      const { data, error } = await dashboardService.getDashboardPathologistStats();
      if (error) {
        this.$alertError(error);
        return null;
      }
      return this.transformStatsData(data);
    },
    async fetchCasesReportedPerYearData() {
      if (!this.$isAuthorized(['Case_CaseForm_Update'])) {
        return null;
      }
      const { data, error } = await dashboardService.getPathologistCaseReportedPerYear();
      if (error) {
        this.$alertError(error);
        return null;
      }
      return data;
    },
    async fetchCasesReportedPerYearDay() {
      if (!this.$isAuthorized(['Case_CaseForm_Update'])) {
        return null;
      }
      const { data, error } = await dashboardService.getPathologistCaseReportedPerDay();
      if (error) {
        this.$alertError(error);
        return null;
      }
      return data;
    },
    async fetchAverageTurnAroundData() {
      if (!this.$isAuthorized(['Case_CaseForm_Update'])) {
        return null;
      }
      const { data, error } = await dashboardService.getPathologistAverageTurnAround();
      if (error) {
        this.$alertError(error);
        return null;
      }
      return data;
    },
    async fetchPathologistUpcomingEvents() {
      const { data, error } = await dashboardService.getPathologistUpcomingEvents();
      if (error) {
        this.$alertError(error);
        return null;
      }
      return data;
    },
  },
};
</script>

<style></style>
